<template>
  <div class="main-content">
    <b-card>
      <good-data-table
        ref="leave_types"
        :columns="columns"
        :no-serial="true"
        :total-column="'response.responseData.total'"
        :data-column="'response.responseData.data'"
        :api-endpoint="'/hrm/leave_type'"
        @add-new-info="New_Type"
        :buttonLabel="'Add'"
      />
    </b-card>
    <validation-observer ref="Create_Type">
      <b-modal hide-footer size="md" id="New_Type" :title="editmode ? $t('Edit') : $t('Add')">
        <b-form @submit.prevent="Submit_Type">
          <b-row>
            <!-- Leave Type -->
            <b-col md="12">
              <validation-provider name="title" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group :label="$t('Title') + ' ' + '*'">
                  <b-form-input
                    :placeholder="$t('Enter title')"
                    :state="getValidationState(validationContext)"
                    aria-describedby="title-feedback"
                    label="title"
                    v-model="leave_type.title"
                  ></b-form-input>
                  <b-form-invalid-feedback id="title-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="12" class="mt-3">
              <b-button variant="primary" type="submit" :disabled="SubmitProcessing"><i class="i-Yes me-2 font-weight-bold"></i> {{ $t('submit') }}</b-button>
              <div v-once class="typo__p" v-if="SubmitProcessing">
                <div class="spinner sm spinner-primary mt-3"></div>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import Vue from 'vue'
import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
const Fire = new Vue()
const apiUrl = `${jwtDefaultConfig.apiEndpoint}/hrm`
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'

export default {
  metaInfo: {
    title: 'Leave Type',
  },
  components: {
    GoodDataTable,
  },
  data() {
    return {
      isLoading: true,
      SubmitProcessing: false,
      serverParams: {
        columnFilters: {},
        sort: {
          field: 'id',
          type: 'desc',
        },
        page: 1,
        perPage: 10,
      },
      selectedIds: [],
      totalRows: '',
      search: '',
      limit: '10',
      editmode: false,
      leave_types: [],
      leave_type: {
        title: '',
      },
    }
  },

  computed: {
    columns() {
      return [
        {
          label: this.$t('Leave Type'),
          field: 'title',
        },

        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.Edit_Type(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.$swal({
                  title: this.$t('You want to delete?'),
                  iconHtml: this.$helpers.swalIcon('alert.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.Remove_Type(props)
                  }
                })
              },
            },
          ],
        },
      ]
    },
  },

  methods: {
    //---- update Params Table
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    //---- Event Page Change
    onPageChange({ currentPage }) {
      if (this.serverParams.page !== currentPage) {
        this.updateParams({ page: currentPage })
        this.Get_leaves_type(currentPage)
      }
    },

    //---- Event Per Page Change
    onPerPageChange({ currentPerPage }) {
      if (this.limit !== currentPerPage) {
        this.limit = currentPerPage
        this.updateParams({ page: 1, perPage: currentPerPage })
        this.Get_leaves_type(1)
      }
    },

    //---- Event Select Rows
    selectionChanged({ selectedRows }) {
      this.selectedIds = []
      selectedRows.forEach((row, index) => {
        this.selectedIds.push(row.id)
      })
    },

    //---- Event Sort Change

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      })
      this.Get_leaves_type(this.serverParams.page)
    },

    //---- Event Search
    onSearch(value) {
      this.search = value.searchTerm
      this.Get_leaves_type(this.serverParams.page)
    },

    //---- Validation State Form
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },

    //------------- Submit Validation Create & Edit leave type
    Submit_Type() {
      this.$refs.Create_Type.validate().then(success => {
        if (!success) {
          showErrorNotification(this, 'Please fill the form correctly')
        } else {
          if (!this.editmode) {
            this.Create_Type()
          } else {
            this.Update_Type()
          }
        }
      })
    },

    //------------------------------ Modal (create leave type) -------------------------------\\
    New_Type() {
      this.reset_Form()
      this.editmode = false
      this.$bvModal.show('New_Type')
    },

    //------------------------------ Modal (Update leave type) -------------------------------\\
    Edit_Type(leave_type) {
      this.Get_leaves_type(this.serverParams.page)
      this.reset_Form()
      this.leave_type = leave_type
      this.editmode = true
      this.$bvModal.show('New_Type')
    },

    //--------------------------Get ALL leave_type ---------------------------\\

    Get_leaves_type(page) {
      // Start the progress bar.
      axios
        .get(apiUrl + '/leave_type?page=' + page + '&SortField=' + this.serverParams.sort.field + '&SortType=' + this.serverParams.sort.type + '&search=' + this.search + '&limit=' + this.limit)
        .then(response => {
          this.totalRows = response.data.totalRows
          this.leave_types = response.data.leave_types

          // Complete the animation of theprogress bar.
          this.isLoading = false
        })
        .catch(response => {
          // Complete the animation of theprogress bar.
          setTimeout(() => {
            this.isLoading = false
          }, 500)
        })
    },

    //------------------------------- Create leave_type ------------------------\\
    Create_Type() {
      this.SubmitProcessing = true
      axios
        .post(apiUrl + '/leave_type', {
          title: this.leave_type.title,
        })
        .then(response => {
          this.SubmitProcessing = false
          Fire.$emit('Event_Type')
          showSuccessNotification(this, 'Created successfully!')
        })
        .catch(error => {
          this.SubmitProcessing = false
          showErrorNotification(this, 'Invalid Data')
        })
    },

    //------------------------------- Update leave_type ------------------------\\
    Update_Type() {
      this.SubmitProcessing = true
      axios
        .put(apiUrl + '/leave_type/' + this.leave_type.id, {
          title: this.leave_type.title,
        })
        .then(response => {
          this.SubmitProcessing = false
          Fire.$emit('Event_Type')
          showSuccessNotification(this, 'Updated successfully!')
        })
        .catch(error => {
          this.SubmitProcessing = false
          showErrorNotification(this, 'Invalid Data')
        })
    },

    //------------------------------- reset Form ------------------------\\
    reset_Form() {
      this.leave_type = {
        id: '',
        title: '',
      }
    },

    //------------------------------- Delete leave_type ------------------------\\
    Remove_Type(props) {
      axios
        .delete(apiUrl + '/leave_type/' + props.id)
        .then(() => {
          showSuccessNotification(this, 'Deleted successfully!')

          Fire.$emit('Delete_Type')
        })
        .catch(() => {
          showErrorNotification(this, 'Something went wrong while deleting item!')
        })
    },

    //---- Delete leave type by selection

    delete_by_selected() {
      this.$swal({
        text: '',
        title: this.$t('Please confirm you want to delete this item?'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // Start the progress bar.
          axios
            .post(apiUrl + '/leave_type/delete/by_selection', {
              selectedIds: this.selectedIds,
            })
            .then(() => {
              showSuccessNotification(this, 'Deleted successfully!')

              Fire.$emit('Delete_Type')
            })
            .catch(() => {
              // Complete the animation of theprogress bar.
              showErrorNotification(this, 'Something went wrong while deleting item!')
            })
        }
      })
    },
  },

  //----------------------------- Created function-------------------\\

  created: function () {
    this.$refs.leave_types.loadItems()

    Fire.$on('Event_Type', () => {
      setTimeout(() => {
        this.$refs.leave_types.loadItems()
        this.$bvModal.hide('New_Type')
      }, 500)
    })

    Fire.$on('Delete_Type', () => {
      setTimeout(() => {
        this.$refs.leave_types.loadItems()
      }, 500)
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
